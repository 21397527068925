package se.fluen.web.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.H1
import se.fluen.styles.OutlinedButtonLinkVariant
import se.fluen.web.values.Res

@Composable
fun ClosingSection() {
    val colorMode by ColorMode.currentState
    Column(
        Modifier.width(99.vw).backgroundColor(colorMode.toSitePalette().primary).padding(topBottom = 3.cssRem)
            .margin(topBottom = 5.vh),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        H1 {
            SpanText(Res.string.closing_headline)
        }
        SpanText(Res.string.closing_subheadline)
        Row(Modifier.gap(1.cssRem).margin(top = 24.px)) {
            Link(Res.link.register, Res.string.signup_now, variant = OutlinedButtonLinkVariant)
            Link(Res.link.contact, Res.string.contact_sales, variant = OutlinedButtonLinkVariant)
        }
    }
}