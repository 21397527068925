package se.fluen.web.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.JustifySelf
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import gg.bluepeak.kobwebsdk.values.anchor
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import se.fluen.styles.ButtonLinkVariant
import se.fluen.styles.OutlinedButtonLinkVariant
import se.fluen.web.components.layouts.PageLayout
import se.fluen.web.components.sections.ClosingSection
import se.fluen.web.components.sections.Features
import se.fluen.web.components.sections.Newsletter
import se.fluen.web.components.sections.Showcase
import se.fluen.web.theme.styles.HeadlineTextStyle
import se.fluen.web.theme.styles.SubheadlineTextStyle
import se.fluen.web.values.Res

val TagStyle = CssStyle {
    base { Modifier.width(Width.MinContent).fontWeight(FontWeight.Bold).justifySelf(JustifySelf.Center) }
}

@Page
@Composable
fun HomePage() {
    val colorMode by ColorMode.currentState
    PageLayout(Res.string.fluense) {
        HeroSection()

        Column(
            Modifier.fillMaxWidth().margin(topBottom = 5.vh),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            H4 {
                SpanText(
                    Res.string.feature_overview_headline,
                    SubheadlineTextStyle.toModifier().fontWeight(FontWeight.Normal)
                )
            }
            SimpleGrid(numColumns(3, md = 5), Modifier.fillMaxWidth().gap(2.cssRem)) {
                SpanText(Res.string.feature_tag_focused_learning, TagStyle.toModifier())
                SpanText(Res.string.feature_tag_study_modes, TagStyle.toModifier())
                SpanText(Res.string.feature_tag_cloud, TagStyle.toModifier())
                SpanText(Res.string.feature_tag_offline, TagStyle.toModifier())
                SpanText(Res.string.feature_tag_ai, TagStyle.toModifier())
            }
        }
        Features()
        Showcase()
        Newsletter()
        ClosingSection()
    }
}

@Composable
private fun HeroSection() {
    Row(
        Modifier.fillMaxWidth().gap(2.cssRem).margin(topBottom = 10.vh),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        Column(Modifier.gap(2.cssRem)) {
            Div(HeadlineTextStyle.toAttrs()) {
                SpanText(
                    Res.string.hero_headline
                )
            }

            Div(SubheadlineTextStyle.toAttrs()) {
                SpanText(Res.string.hero_subheadline)
            }

            Row(Modifier.gap(1.cssRem)) {
                Link(Res.link.register, Res.string.join_now, variant = ButtonLinkVariant)
                Link(
                    Res.link.home.anchor(Res.link.featuresId),
                    Res.string.explore_features,
                    variant = OutlinedButtonLinkVariant
                )
            }
        }
        Image(Res.image.logo, Modifier.displayIfAtLeast(Breakpoint.MD).height(clamp(20.cssRem, 25.vw, 25.cssRem)))
    }
}
