package se.fluen.web.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import se.fluen.web.values.Res

@Composable
fun Footer() {
    se.fluen.components.sections.Footer {
        Column {
            Link(Res.link.home, Res.string.home, variant = UncoloredLinkVariant)
            Link(Res.link.pricing, Res.string.pricing, variant = UncoloredLinkVariant)
            Link(Res.link.about, Res.string.about_us, variant = UncoloredLinkVariant)
            Link(Res.link.contact, Res.string.contact, variant = UncoloredLinkVariant)
        }
        Column {
            Link(Res.link.downloads, Res.string.downloads, variant = UncoloredLinkVariant)
            Link(Res.link.blog, Res.string.blog, variant = UncoloredLinkVariant)
        }
        Column {
            Link(Res.link.privacy, Res.string.privacy_policy, variant = UncoloredLinkVariant)
            Link(Res.link.tos, Res.string.terms_of_service, variant = UncoloredLinkVariant)
        }
    }
}