package se.fluen.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiBrightnessAuto
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDarkMode
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLightMode
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import gg.bluepeak.kobwebsdk.colorMode.COLOR_MODE_KEY
import gg.bluepeak.kobwebsdk.colorMode.systemDefault
import gg.bluepeak.kobwebsdk.styles.CircleButtonVariant
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun ColorModeButton(
    lightText: String,
    darkText: String,
    autoText: String,
    mobilePlacement: PopupPlacement = PopupPlacement.TopRight,
) {
    var colorMode by ColorMode.currentState
    Popup({
        if (colorMode.isLight) MdiDarkMode(style = IconStyle.ROUNDED) else MdiLightMode(style = IconStyle.ROUNDED)
    }, CircleButtonVariant, modifier = Modifier.height(40.px).width(40.px)) {
        Button({
            colorMode = ColorMode.LIGHT
            localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
        }, variant = UncoloredButtonVariant, modifier = Modifier.fillMaxWidth()) {
            Row(
                Modifier.fillMaxWidth().gap(.2.cssRem),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                MdiLightMode(style = IconStyle.ROUNDED)
                SpanText(lightText, Modifier.margin(left = .5.cssRem))
            }
        }
        Button({
            colorMode = ColorMode.DARK
            localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
        }, variant = UncoloredButtonVariant, modifier = Modifier.fillMaxWidth()) {
            Row(
                Modifier.fillMaxWidth().gap(.2.cssRem),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                MdiDarkMode(style = IconStyle.ROUNDED)
                SpanText(darkText, Modifier.margin(left = .5.cssRem))
            }
        }
        Button({
            colorMode = ColorMode.systemDefault
            localStorage.removeItem(COLOR_MODE_KEY)
        }, variant = UncoloredButtonVariant, modifier = Modifier.fillMaxWidth()) {
            Row(
                Modifier.fillMaxWidth().gap(.2.cssRem),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                MdiBrightnessAuto(style = IconStyle.ROUNDED)
                SpanText(autoText, Modifier.margin(left = .5.cssRem))
            }
        }
    }
}