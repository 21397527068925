package se.fluen.styles

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.anyLink
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import se.fluen.theme.Theme

val NavLinkVariant = LinkStyle.addVariant {
    anyLink { Modifier.color(ColorVar.value()) }
    hover {
        Modifier
            .textDecorationLine(TextDecorationLine.None)
            .color(Theme.primary)
    }
}

val ButtonLinkVariant = LinkStyle.addVariant(@Composable { ButtonStyle.toModifier() }) {
    anyLink {
        Modifier.color(Theme.white)
            .textDecorationLine(TextDecorationLine.None)
    }
    base {
        Modifier.padding(topBottom = 9.px, leftRight = 1.2.cssRem)
    }
}

val SecondaryButtonLinkVariant = LinkStyle.addVariant(@Composable { ButtonStyle.toModifier(SecondaryButtonVariant) }) {
    anyLink {
        Modifier
            .color(Theme.black)
            .textDecorationLine(TextDecorationLine.None)
    }
    base {
        Modifier.padding(topBottom = 9.px, leftRight = 1.2.cssRem)
            .textAlign(TextAlign.Center)
            .fontSize(1.cssRem)
    }
}

val OutlinedButtonLinkVariant = LinkStyle.addVariant(@Composable { ButtonStyle.toModifier(OutlinedButtonVariant) }) {
    anyLink {
        Modifier.color(colorMode.toPalette().color)
            .textDecorationLine(TextDecorationLine.None)
    }
    base {
        Modifier.padding(topBottom = .45.cssRem, leftRight = 1.2.cssRem)
    }
}

val CardLinkVariant = LinkStyle.addVariant {
    val palette = colorMode.toPalette()
    anyLink {
        Modifier.color(palette.color)
            .textDecorationLine(TextDecorationLine.None)
    }
    hover {
        Modifier.backgroundColor(palette.background.shifted(colorMode, 0.2f))
    }
}

val UnstyledLinkVariant = LinkStyle.addVariant {
    anyLink {
        Modifier.color(colorMode.toPalette().color)
            .textDecorationLine(TextDecorationLine.None)
    }
}