package gg.bluepeak.kobwebsdk.colorMode

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.setVariable
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import gg.bluepeak.kobwebsdk.values.BackgroundColor
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.MediaQueryListEvent

const val COLOR_MODE_KEY = "colorMode"

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    ctx.config.initialColorMode = localStorage.getItem(COLOR_MODE_KEY)?.let { ColorMode.valueOf(it) }
        ?: ColorMode.systemDefault
}

@Composable
fun colorModeConfig(lightBackground: Color, darkBackground: Color) {
    var colorMode by ColorMode.currentState
    LaunchedEffect(Unit) {
        colorModeQuery().addEventListener("change", {
            localStorage.getItem(COLOR_MODE_KEY) ?: let { _ ->
                colorMode = (if (it is MediaQueryListEvent && it.matches) ColorMode.DARK else ColorMode.LIGHT)
            }
        })
    }

    LaunchedEffect(colorMode) {
        document.body!!.setVariable(BackgroundColor, if (colorMode.isLight) lightBackground else darkBackground)
    }
}

private fun colorModeQuery() = window.matchMedia("(prefers-color-scheme: dark)")

val ColorMode.Companion.systemDefault
    get() = colorModeQuery().let { query ->
        if (query.matches) ColorMode.DARK else ColorMode.LIGHT
    }