package se.fluen.web.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.window
import se.fluen.web.values.Res

@Page
@Composable
fun Discord() {
    val ctx = rememberPageContext()
    LaunchedEffect(Unit) {
        if (ctx.isExporting) return@LaunchedEffect
        window.location.replace(Res.link.discord_invite)
    }
}