package se.fluen.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import kotlinx.browser.window
import se.fluen.web.values.Res

@Composable
fun LanguageSelector() {
    val ctx = rememberPageContext()
    se.fluen.components.widgets.LanguageSelector(
        Res.string.language_selector_headline,
        Res.string.locale,
        Res.locales
    ) {
        ctx.router.navigateTo(
            window.location.href.replace("/${Res.string.locale}/", "/${it.iso}/"),
            openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
        )
    }
}