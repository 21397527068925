package se.fluen.web.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.http.HttpMethod
import com.varabyte.kobweb.browser.http.fetch
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.TextInput
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.H1
import se.fluen.web.values.Res

@Composable
fun Newsletter() {
    val scope = rememberCoroutineScope()
    var email by remember { mutableStateOf("") }

    suspend fun send() {
        window.fetch(
            HttpMethod.POST,
            resource = "https://herald.bluepeak.cc/api/subscriber",
            headers = mapOf("Content-Type" to "application/json"),
            body = """
                {
                  "site": "fluen.se",
                  "email": "$email",
                  "firstName": null,
                  "lastName": null,
                  "language": null,
                  "newsletter": true
                }
            """.trimIndent().encodeToByteArray()
        )
    }

    Column(
        Modifier.width(99.vw).padding(topBottom = 3.cssRem),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        H1 {
            SpanText(Res.string.newsletter_headline)
        }
        SpanText(Res.string.newsletter_subheadline, Modifier.width(80.vw).textAlign(TextAlign.Center))

        Column(
            Modifier.width(clamp(15.cssRem, 30.vw, 30.cssRem)).gap(1.cssRem).margin(top = 1.cssRem),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            TextInput(
                email,
                { email = it },
                Modifier.fillMaxWidth(),
                placeholder = Res.string.contact_email_placeholder
            )
            Button({ scope.launch { send() } }, Modifier.fillMaxWidth()) {
                SpanText(Res.string.newsletter_subscribe)
            }
        }
    }
}