package se.fluen.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import gg.bluepeak.kobwebsdk.components.widgets.ChipStyle

val ChipVariant = ChipStyle.addVariant {
    val palette = colorMode.toPalette()
    base {
        Modifier
            .color(palette.color)
    }
    hover {
        Modifier
            .backgroundColor(palette.background.shifted(colorMode, 1f))
            .color(colorMode.opposite.toPalette().color)
    }
}

val ActiveChip = ChipStyle.addVariant {
    val palette = colorMode.opposite.toPalette()
    base {
        Modifier
            .backgroundColor(palette.background)
            .color(palette.color)
    }
    hover {
        Modifier
            .backgroundColor(palette.background.shifted(colorMode, 1f))
            .color(colorMode.opposite.toPalette().color)
    }
}