package se.fluen.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.JustifyItems
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import se.fluen.web.theme.styles.SubheadlineTextStyle

val FeatureImageStyle = CssStyle {
    base { Modifier.display(DisplayStyle.Grid).placeItems(AlignItems.Center, JustifyItems.Center).gridRow(1) }
    Breakpoint.MD { Modifier.display(DisplayStyle.Flex).gridRow("auto") }
}

@Composable
fun Feature(
    headline: String,
    subheadline: String,
    description: String,
    image: String,
    imageFirst: Boolean = false,
    cta: @Composable () -> Unit,
) {
    @Composable
    fun Image() {
        Div(FeatureImageStyle.toAttrs()) {
            if (!imageFirst) Spacer()
            Image(image, Modifier.height(clamp(20.cssRem, 25.vw, 25.cssRem)))
            if (imageFirst) Spacer()
        }
    }
    SimpleGrid(
        numColumns = numColumns(1, md = 2),
        Modifier.fillMaxWidth().gap(2.cssRem),
    ) {
        if (imageFirst) Image()
        Column {
            SpanText(subheadline, SubheadlineTextStyle.toModifier().fontWeight(FontWeight.Bold))
            H1 {
                SpanText(headline)
            }
            P {
                Text(description)
            }
            cta()
        }
        if (!imageFirst) Image()
    }
}