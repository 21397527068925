package se.fluen.web.values

import gg.bluepeak.kobwebsdk.values.Locale
import gg.bluepeak.kobwebsdk.values.ResId
import gg.bluepeak.kobwebsdk.values.ResLink
import strings.StringsEn

object Res {
    val locales get() = listOf(Locale.EN, Locale.DE, Locale.ZH)
    val string: StringsEn = StringsEn
    val link = Links
    val image = Images

    object Links {
        val fluense: ResLink = "https://fluen.se/${string.locale}/"
        const val login: ResLink = "https://app.fluen.se"
        const val register: ResLink = "https://app.fluen.se"
        const val discord_invite: ResLink = "https://discord.gg/mBCGq7FAH6"

        const val download_android_play: ResLink = "https://play.google.com/store/apps/details?id=fluen.se"
        const val download_android_apk: ResLink = "https://downloads.fluen.se/Fluense-latest.apk"
        const val download_ios: ResLink = ""
        const val download_mac: ResLink = ""
        const val download_windows: ResLink = "https://downloads.fluen.se/Fluense-latest.msi"

        const val home: ResLink = "/"
        const val contact: ResLink = "/contact"
        const val features: ResLink = "/#features"
        const val about: ResLink = features
        const val discord: ResLink = "/discord"
        const val pricing: ResLink = "/pricing"
        const val privacy: ResLink = "/privacy"
        const val tos: ResLink = "/tos"
        const val downloads: ResLink = "/downloads"
        const val blog: ResLink = "https://blog.fluen.se"

        val featuresId: ResId = "features"
    }

    object Images {
        const val logo_full_dark = "/drawable/logos/logo_full_dark.svg"
        const val logo_full_light = "/drawable/logos/logo_full_light.svg"
        const val logo_text_dark = "/drawable/logos/Text_dark.svg"
        const val logo_text_light = "/drawable/logos/Text_light.svg"
        const val logo = "/drawable/logos/Logo.svg"
        const val feature_study_modes = "/drawable/assets/study_modes.svg"
        const val feature_smart = "/drawable/assets/ai.svg"
        const val feature_cloud = "/drawable/assets/cloud.svg"
        const val showcase_cardlist_light = "/drawable/showcases/cardlist_light.webp"
        const val showcase_cardlist_dark = "/drawable/showcases/cardlist_dark.webp"
        const val showcase_card_dark = "/drawable/showcases/card_dark.webp"
        const val showcase_card_light = "/drawable/showcases/card_light.webp"
        const val showcase_deck_light = "/drawable/showcases/deck_light.webp"
        const val showcase_deck_dark = "/drawable/showcases/deck_dark.webp"
        const val showcase_study_dark = "/drawable/showcases/study_dark.webp"
        const val showcase_study_light = "/drawable/showcases/study_light.webp"
        const val contact = "/drawable/assets/contact.svg"
        const val downloads = "/drawable/assets/downloads.svg"
        const val google_play = "/drawable/assets/google-play-badge.svg"
        const val windows = "/drawable/assets/windows.svg"
    }
}