package se.fluen.theme

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import gg.bluepeak.kobwebsdk.values.BackgroundColor
import org.jetbrains.compose.web.css.px

@InitSilk
fun initStyleSheet(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier.styleModifier {
            property("-webkit-touch-callout", "none !important")
        }
    }
    ctx.stylesheet.registerStyle("*") {
        cssRule("::-webkit-scrollbar") {
            Modifier
                .width(8.px)
                .height(8.px)
                .backgroundColor(BackgroundColor.value())
        }
        cssRule("::-webkit-scrollbar-thumb") {
            Modifier
                .borderRadius(10.px)
                .backgroundColor(Theme.primary)
        }
        cssRule("::-webkit-scrollbar-corner") {
            Modifier
                .backgroundColor(com.varabyte.kobweb.compose.css.BackgroundColor.Transparent)
        }
    }
    ctx.stylesheet.registerStyleBase("a") {
        Modifier.styleModifier {
            property("-webkit-user-select", "none !important")
        }
    }
}