package se.fluen.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.DisplayStyle

val NoScrollbar = CssStyle {
    base {
        Modifier
            .styleModifier {
                property("scrollbar-width", "none")
            }
    }
    cssRule("::-webkit-scrollbar") {
        Modifier
            .display(DisplayStyle.None)
    }
}