package se.fluen.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.BoxSizing
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.toAttrs
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import se.fluen.theme.Theme

val LdsRingStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.InlineBlock)
            .position(Position.Relative)
            .size(32.px)
    }
    cssRule(" div") {
        Modifier
            .boxSizing(BoxSizing.BorderBox)
            .display(DisplayStyle.Block)
            .position(Position.Absolute)
            .border {
                width(2.px)
                style(LineStyle.Solid)
                color(Theme.primary, Colors.Transparent, Colors.Transparent, Colors.Transparent)
            }
            .borderRadius(50.percent)
            .animation(
                LdsRing.toAnimation(
                    colorMode,
                    duration = 1.2.s,
                    timingFunction = AnimationTimingFunction.cubicBezier(0.5, 0.0, 0.5, 1.0),
                    iterationCount = AnimationIterationCount.Infinite
                )
            )
            .size(32.px)
    }
    cssRule(" div:nth-child(1)") {
        Modifier.styleModifier { property("animation-delay", "-0.45s") }
    }
    cssRule(" div:nth-child(2)") {
        Modifier.styleModifier { property("animation-delay", "-0.3s") }
    }
    cssRule(" div:nth-child(3)") {
        Modifier.styleModifier { property("animation-delay", "-0.15s") }
    }
}
val LdsRing = Keyframes {
    0.percent {
        Modifier
            .transform { rotate(0.deg) }
    }
    100.percent {
        Modifier
            .transform { rotate(360.deg) }
    }
}


@Composable
fun LoadingSpinner() {
    Div(LdsRingStyle.toAttrs()) {
        Div { }
        Div { }
        Div { }
        Div { }
    }
}