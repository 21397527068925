package se.fluen.styles

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import se.fluen.theme.Theme

val PrimaryButtonVariant = ButtonStyle.addVariantBase { Modifier }

val OutlinedButtonVariant = ButtonStyle.addVariantBase {
    val palette = colorMode.toPalette()
    Modifier
        .border(width = 2.px, style = LineStyle.Solid, color = palette.color)
        .setVariable(ButtonVars.BackgroundDefaultColor, palette.background)
        .setVariable(ButtonVars.BackgroundHoverColor, palette.background.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundFocusColor, palette.background.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundPressedColor, palette.background.shifted(colorMode, .2f))
        .setVariable(ButtonVars.Color, palette.color)
}

val SecondaryButtonVariant = ButtonStyle.addVariantBase {
    val palette = colorMode.toSitePalette()
    Modifier
        .setVariable(ButtonVars.BackgroundDefaultColor, palette.secondary)
        .setVariable(ButtonVars.BackgroundHoverColor, palette.secondary.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundFocusColor, palette.secondary.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundPressedColor, palette.secondary.shifted(colorMode, .2f))
        .setVariable(ButtonVars.Color, Theme.black)
}

val ErrorButtonVariant = ButtonStyle.addVariantBase {
    val palette = colorMode.toSitePalette()
    Modifier
        .setVariable(ButtonVars.BackgroundDefaultColor, palette.error)
        .setVariable(ButtonVars.BackgroundHoverColor, palette.error.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundFocusColor, palette.error.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundPressedColor, palette.error.shifted(colorMode, .2f))
        .setVariable(ButtonVars.Color, Theme.black)
}
val ActiveButtonVariant = ButtonStyle.addVariantBase {
    val p = colorMode.toPalette()
    Modifier
        .setVariable(ButtonVars.BackgroundDefaultColor, p.color)
        .setVariable(ButtonVars.BackgroundHoverColor, p.color.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundFocusColor, p.color.shifted(colorMode, .1f))
        .setVariable(ButtonVars.BackgroundPressedColor, p.color.shifted(colorMode, .2f))
        .setVariable(ButtonVars.Color, p.background)
}

val TextButtonVariant = ButtonStyle.addVariant(@Composable { ButtonStyle.toModifier(UncoloredButtonVariant) }) {
    base {
        Modifier.backgroundColor(Colors.Transparent)
    }
    hover {
        Modifier.color(Theme.primary)
    }
}

val UnstyledButtonVariant = ButtonStyle.addVariant {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .padding(0.px)
            .height(Height.FitContent)
    }
    hover {
        Modifier
            .backgroundColor(Colors.Transparent)
    }
}