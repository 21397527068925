package se.fluen.styles

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.forms.InputStyle
import com.varabyte.kobweb.silk.components.forms.InputVars
import com.varabyte.kobweb.silk.components.overlay.TooltipStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.modifyStyle
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.s
import se.fluen.theme.Theme

@InitSilk
fun replaceSilkStyles(ctx: InitSilkContext) {
    ctx.theme.modifyStyleBase(ButtonStyle) {
        Modifier.setVariable(ButtonVars.Color, Theme.white)
            .fontWeight(FontWeight.Normal)
    }

    ctx.theme.modifyStyle(InputStyle) {
        base {
            Modifier.height(3.25.cssRem)
                .setVariable(InputVars.BorderFocusColor, Theme.primary)
        }
        cssRule("::-webkit-search-cancel-button") {
            Modifier
                .styleModifier { property("-webkit-appearance", "none") }
                .appearance(Appearance.None)
                .backgroundImage(BackgroundImage.of(url("drawable/cancel.svg")))
                .height(1.5.em)
                .width(1.5.em)
                .backgroundSize(BackgroundSize.Contain)
                .cursor(Cursor.Pointer)
                .translateX(1.3.cssRem)
                .transition(Transition.all(0.1.s, AnimationTimingFunction.EaseIn))
                .thenIf(colorMode.isDark, Modifier.filter(invert(1)))
        }
        cssRule(":focus::-webkit-search-cancel-button, input:hover::-webkit-search-cancel-button") {
            Modifier
                .translateX(0.cssRem)
        }
    }

    ctx.theme.modifyStyle(TooltipStyle) {
        cssRule(" span") {
            Modifier
                .fontSize(0.8.cssRem)
        }
    }

}