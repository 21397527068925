package gg.bluepeak.kobwebsdk.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

sealed interface ChipKind : ComponentKind

val ChipStyle = CssStyle<ChipKind> {
    base {
        Modifier
            .fontSize(FontSize.Smaller)
            .borderRadius(4.px)
            .border {
                width(1.px)
                style(LineStyle.Solid)
                color(colorMode.toPalette().color)
            }
            .height(1.9.cssRem)
            .padding(leftRight = 1.cssRem)
            .fontWeight(600)
            .transition(
                Transition.of("background-color", duration = ButtonVars.ColorTransitionDuration.value()),
                Transition.of("border-color", duration = ButtonVars.ColorTransitionDuration.value())
            )
    }
    hover {
        Modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(ButtonVars.BackgroundDefaultColor.value())
    }
}

@Composable
fun Chip(
    text: String,
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<ChipKind>? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
) {
    Row(ChipStyle.toModifier(variant).then(modifier), verticalAlignment = Alignment.CenterVertically) {
        leadingIcon?.let {
            Box(
                Modifier.margin(left = (-.9).cssRem, right = .4.cssRem),
                contentAlignment = Alignment.Center
            ) {
                it()
            }
        }
        SpanText(text)
        trailingIcon?.let {
            Box(
                Modifier.margin(right = (-.9).cssRem, left = .4.cssRem),
                contentAlignment = Alignment.Center
            ) {
                it()
            }
        }
    }
}