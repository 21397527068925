package se.fluen.web.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Checkbox
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import se.fluen.components.widgets.Accordion
import se.fluen.components.widgets.AccordionItem
import se.fluen.styles.ButtonLinkVariant
import se.fluen.styles.OutlinedButtonLinkVariant
import se.fluen.web.components.layouts.PageLayout
import se.fluen.web.values.Res

val PricingCardStyle = CssStyle.base {
    Modifier
        .backgroundColor(colorMode.toPalette().background.shifted(colorMode, 0.1f))
        .borderRadius(12.px)
        .padding(1.5.cssRem)
        .height(18.cssRem)
        .width(17.cssRem)
        .margin(top = 1.cssRem)
}

val PricingFeatureListStyle = CssStyle.base {
    Modifier.gap(.4.cssRem).padding(left = 1.cssRem).margin(bottom = 2.cssRem)
}

val PricingPlanTagStyle = CssStyle.base {
    Modifier.fontWeight(FontWeight.Bold).margin(top = 2.cssRem).padding(left = 1.cssRem)
}

val PricingClosingStyle = CssStyle {
    base {
        Modifier.padding(2.cssRem).margin(top = 2.cssRem)
            .backgroundColor(colorMode.toPalette().background.shifted(colorMode, 0.1f))
            .fillMaxWidth().borderRadius(12.px)
            .margin(5.cssRem)
    }
}

@Page
@Composable
fun Pricing() {
    PageLayout(Res.string.pricing) {
        val colorMode by ColorMode.currentState
        H1 {
            SpanText(Res.string.pricing)
        }
        SpanText(
            Res.string.pricing_headline,
            Modifier.width(clamp(18.cssRem, 35.vw, 32.cssRem)).textAlign(TextAlign.Center)
        )
        PricingPlans(colorMode)

        H1 {
            SpanText(Res.string.frequently_asked_questions)
        }
        Accordion(
            listOf(
                AccordionItem(Res.string.pricing_question_free_trial) { Text(Res.string.pricing_answer_free_trial) },
                AccordionItem(Res.string.pricing_question_annual) { Text(Res.string.pricing_answer_annual) },
                AccordionItem(Res.string.pricing_question_bulk) { Text(Res.string.pricing_answer_bulk) },
            )
        )

        Row(PricingClosingStyle.toModifier(), horizontalArrangement = Arrangement.SpaceBetween) {
            Column {
                H1 {
                    SpanText(Res.string.pricing_closing_headline)
                }
                SpanText(Res.string.pricing_closing_subheadline)
                Link(
                    Res.link.register,
                    Res.string.signup_now,
                    Modifier.margin(top = 2.cssRem),
                    variant = ButtonLinkVariant
                )
            }
            Image(Res.image.logo, Modifier.displayIfAtLeast(Breakpoint.MD).height(clamp(12.cssRem, 25.vw, 20.cssRem)))
        }
    }
}

@Composable
private fun PricingPlans(colorMode: ColorMode) {
    SimpleGrid(numColumns(1, md = 3), Modifier.margin(top = 3.cssRem)) {
        Column {
            Column(PricingCardStyle.toModifier()) {
                SpanText(Res.string.basic, Modifier.fontWeight(FontWeight.Bold))
                P {
                    SpanText(Res.string.free, Modifier.fontWeight(FontWeight.Bold).fontSize(FontSize.XXLarge))
                }
                SpanText(Res.string.pricing_basic_description)
                Box(Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
                    Link(
                        Res.link.register,
                        Res.string.get_started_basic,
                        Modifier.margin(top = 2.cssRem),
                        variant = OutlinedButtonLinkVariant
                    )
                }
            }
            SpanText(Res.string.basic, PricingPlanTagStyle.toModifier())
            HorizontalDivider()
            Column(PricingFeatureListStyle.toModifier()) {
                BasicFeatures()
            }
        }

        Column {
            Column(
                PricingCardStyle.toModifier()
                    .height(20.cssRem)
                    .margin(top = 0.cssRem)
                    .backgroundColor(colorMode.toPalette().background)
                    .border(1.px, LineStyle.Solid, colorMode.toPalette().background.shifted(colorMode, 0.1f))
            ) {
                SpanText(Res.string.pro, Modifier.fontWeight(FontWeight.Bold).padding(top = 1.cssRem))
                P(Modifier.margin(top = 2.3.cssRem, bottom = 1.2.cssRem).toAttrs()) {
//                    SpanText("$", Modifier.verticalAlign(VerticalAlign.Super))
//                    SpanText("9", Modifier.fontWeight(FontWeight.Bold).fontSize(FontSize.XXLarge))
//                    SpanText(Res.string.price_monthly, Modifier.fontSize(FontSize.Smaller))
                    SpanText(Res.string.pricing_beta_description)
                    Br()
                    SpanText(Res.string.pricing_beta_cta)
                }
//                SpanText(Res.string.pricing_pro_description)
                Box(Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
                    Link(
                        Res.link.discord,
                        Res.string.join_now,
                        Modifier.margin(top = 2.cssRem),
                        variant = ButtonLinkVariant
                    )
                }
            }
            SpanText(Res.string.pro, PricingPlanTagStyle.toModifier().margin(top = 1.cssRem))
            HorizontalDivider()
            Column(PricingFeatureListStyle.toModifier()) {
                BasicFeatures()
                ProFeatures()
            }
        }

        Column {
            Column(PricingCardStyle.toModifier()) {
                SpanText(Res.string.business, Modifier.fontWeight(FontWeight.Bold))
                P { }
                SpanText(Res.string.pricing_business_description)
                Box(Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
                    Link(
                        Res.link.register,
                        Res.string.contact_sales,
                        Modifier.margin(top = 3.3.cssRem),
                        variant = OutlinedButtonLinkVariant
                    )
                }
            }
            SpanText(Res.string.business, PricingPlanTagStyle.toModifier())
            HorizontalDivider()
            Column(PricingFeatureListStyle.toModifier()) {
                BasicFeatures()
                ProFeatures()
                BusinessFeatures()
            }
        }
    }
}

@Composable
private fun BasicFeatures() {
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_ai) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_cloud) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_offline) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_core_modes) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_custom_decks) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_progress_tracking) }
}

@Composable
private fun ProFeatures() {
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_advanced_modes) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_collaborative_decks) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_pre_release) }
}

@Composable
private fun BusinessFeatures() {
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_advanced_analytics) }
    Checkbox(true, {}) { SpanText(Res.string.pricing_feature_priority_support) }
}