package gg.bluepeak.kobwebsdk.theme

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.silk.theme.colors.ColorMode


open class SitePalette(
    val primary: Color,
    val primaryVariant: Color,
    val secondary: Color,
    val surface: Color,
    val background: Color,
    val error: Color,
    val shadow: Color,
)

object SitePalettes {
    var light: SitePalette = SitePalette(
        primary = Colors.LightBlue,
        primaryVariant = Colors.Blue,
        secondary = Colors.SkyBlue,
        surface = Colors.WhiteSmoke,
        background = Colors.White,
        error = Colors.Red,
        shadow = Colors.LightGray,
    )
    var dark: SitePalette = SitePalette(
        primary = Colors.LightBlue,
        primaryVariant = Colors.Blue,
        secondary = Colors.SkyBlue,
        surface = Colors.DarkGrey,
        background = Colors.Black,
        error = Colors.Red,
        shadow = Colors.LightGray,
    )
}

fun ColorMode.toSitePalette() = when (this) {
    ColorMode.LIGHT -> SitePalettes.light
    ColorMode.DARK -> SitePalettes.dark
}