package se.fluen.theme

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.selectors.selection
import com.varabyte.kobweb.silk.theme.colors.palette.*
import gg.bluepeak.kobwebsdk.theme.SitePalette
import gg.bluepeak.kobwebsdk.theme.SitePalettes

object Theme {
    val primary = Color.rgb(43, 175, 106)
    val primaryVariant = Color.rgb(73, 186, 127)
    val secondary = Color.rgb(231, 231, 231)
    val darkSurface = Color.rgb(13, 14, 16)
    val lightSurface = Color.rgb(217, 217, 217)
    val error = Color.rgb(214, 120, 121)
    val white = Color.rgb(245, 245, 247)
    val black = Color.rgb(29, 29, 31)
    val lightModeShadow = Color.rgb(171, 171, 171)
    val darkModeShadow = Colors.Black
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    SitePalettes.apply {
        light = SitePalette(
            primary = Theme.primary,
            primaryVariant = Theme.primaryVariant,
            secondary = Theme.secondary,
            surface = Theme.lightSurface,
            background = Theme.white,
            error = Theme.error,
            shadow = Theme.lightModeShadow,
        )
        dark = SitePalette(
            primary = Theme.primary,
            primaryVariant = Theme.primaryVariant,
            secondary = Theme.secondary,
            surface = Theme.darkSurface,
            background = Theme.black,
            error = Theme.error,
            shadow = Theme.darkModeShadow,
        )
    }

    ctx.theme.palettes.apply {
        light.apply {
            background = Theme.white
            color = Theme.black
            link.apply {
                default = Theme.primary
                visited = Theme.primaryVariant
            }
            button.apply {
                default = Theme.primary
                hover = Theme.primary.lightened()
                focus = Theme.primary.lightened()
                pressed = Theme.primary.lightened().lightened()
            }
            tooltip.apply {
                background = Theme.black
                color = Theme.white
            }
            checkbox.apply {
                color = Theme.white
                background = Theme.primary
                hover = Theme.primary.lightened()
            }
            input.apply {
                invalidBorder = Theme.error
            }
            switch.apply {
                backgroundOn = Theme.primary
            }
            tab.apply {
                background = Theme.white
                color = Theme.black
                selectedColor = Theme.primary
                selectedBorder = Theme.primary
                hover = Theme.white.darkened()
                selectedBackground = Theme.white
            }
        }
        dark.apply {
            background = Theme.black
            color = Theme.white
            link.apply {
                default = Theme.primary
                visited = Theme.primaryVariant
            }
            button.apply {
                default = Theme.primary
                hover = Theme.primary.lightened()
                focus = Theme.primary.lightened()
                pressed = Theme.primary.lightened().lightened()
            }
            tooltip.apply {
                background = Theme.white
                color = Theme.black
            }
            checkbox.apply {
                color = Theme.white
                background = Theme.primary
                hover = Theme.primary.lightened()
            }
            input.apply {
                invalidBorder = Theme.error
            }
            switch.apply {
                backgroundOn = Theme.primary
            }
            tab.apply {
                background = Theme.black
                color = Theme.white
                selectedColor = Theme.primary
                selectedBorder = Theme.primary
                hover = Theme.black.lightened()
                selectedBackground = Theme.black
            }
        }
    }

    ctx.stylesheet.registerStyle("*") {
        selection {
            Modifier.backgroundColor(Theme.primary)
        }
    }
}