package se.fluen.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.dom.disposableRef
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonKind
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.toModifier
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import se.fluen.styles.PopupSurfaceVariant
import se.fluen.styles.SurfaceStyle

@Composable
fun Popup(
    buttonContent: @Composable RowScope.() -> Unit,
    buttonVariant: CssStyleVariant<ButtonKind>? = null,
    placement: PopupPlacement = PopupPlacement.BottomRight,
    modifier: Modifier = Modifier,
    onActive: () -> Unit = {},
    onDispose: () -> Unit = {},
    popupContent: @Composable ColumnScope.() -> Unit,
) {
    val popup = remember { KeepPopupOpenStrategy.onFocus() }
    val control = remember { OpenClosePopupStrategy.manual() }

    Button(
        { control.isOpen = !control.isOpen; onActive() }, modifier,
        variant = if (buttonVariant == null) UncoloredButtonVariant else UncoloredButtonVariant.then(buttonVariant),
        size = ButtonSize.SM
    ) {
        buttonContent()
    }
    AdvancedPopover(
        target = ElementTarget.PreviousSibling,
        openCloseStrategy = control,
        keepOpenStrategy = popup,
        placementStrategy = PopupPlacementStrategy.of(placement),
        ref = disposableRef {
            it.tabIndex = 0
            it.focus()
            onDispose {
                control.isOpen = false
                onDispose()
            }
        }
    ) {
        Column(SurfaceStyle.toModifier(PopupSurfaceVariant)) {
            popupContent()
        }
    }
}