package se.fluen.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDiscord
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMenu
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import gg.bluepeak.kobwebsdk.components.widgets.IconButton
import gg.bluepeak.kobwebsdk.styles.IconLinkVariant
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
private fun DiscordButton() {
    Link(
        "https://fluen.se/discord",
        openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
        variant = IconLinkVariant
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            MdiDiscord()
        }
    }
}

val NavHeaderStyle = CssStyle.base {
    Modifier.fillMaxWidth().padding(1.cssRem).position(Position.Fixed).backgroundColor(colorMode.toPalette().background)
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}

val MobileMenuStyle = CssStyle {
    base {
        Modifier.display(DisplayStyle.Block).fillMaxWidth()
    }
    Breakpoint.MD {
        Modifier.display(DisplayStyle.None)
    }
}

val DesktopMenuStyle = CssStyle {
    base {
        Modifier.display(DisplayStyle.None)
    }
    Breakpoint.MD {
        Modifier.display(DisplayStyle.Block).fillMaxWidth()
    }
}

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun NavHeader(
    menuItems: @Composable () -> Unit,
    trailingItems: @Composable () -> Unit,
    colorModeButton: @Composable () -> Unit,
    languageDropDown: @Composable () -> Unit,
) {
    val colorMode by ColorMode.currentState
    Row(NavHeaderStyle.toModifier(), horizontalArrangement = Arrangement.Center) {
        Row(Modifier.fillMaxWidth(80.percent), verticalAlignment = Alignment.CenterVertically) {
            Link(
                "https://fluen.se",
                openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
            ) {
                // Block display overrides inline display of the <img> tag, so it calculates centering better
                Image(
                    if (colorMode.isDark) "https://downloads.fluen.se/assets/images/logo_full_light.svg"
                    else "https://downloads.fluen.se/assets/images/logo_full_dark.svg",
                    "Fluense Logo",
                    Modifier.height(2.cssRem).display(DisplayStyle.Block)
                )
            }

            Div(DesktopMenuStyle.toAttrs()) {
                Row(
                    Modifier
                        .fillMaxWidth().gap(1.5.cssRem)
                        .padding(left = 2.cssRem),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    menuItems()
                    Spacer()
                    languageDropDown()
                    DiscordButton()
                    colorModeButton()
                    trailingItems()
                }
            }

            Div(MobileMenuStyle.toAttrs()) {
                Row(
                    Modifier
                        .fillMaxWidth()
                        .fontSize(1.5.cssRem),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }

                    Spacer()

                    IconButton(onClick = { menuState = SideMenuState.OPEN }) {
                        MdiMenu()
                    }

                    if (menuState != SideMenuState.CLOSED) {
                        SideMenu(
                            menuState,
                            close = { menuState = menuState.close() },
                            onAnimationEnd = {
                                if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED
                            },
                            menuItems = menuItems,
                            trailingItems = trailingItems,
                            languageDropDown = languageDropDown,
                            colorModeButton = colorModeButton
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun SideMenu(
    menuState: SideMenuState,
    close: () -> Unit,
    onAnimationEnd: () -> Unit,
    menuItems: @Composable () -> Unit,
    trailingItems: @Composable () -> Unit,
    languageDropDown: @Composable () -> Unit,
    colorModeButton: @Composable () -> Unit,
) {
    Overlay(
        Modifier
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fillMaxHeight()
                    .width(clamp(10.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    // Close button will appear roughly over the hamburger button, so the user can close
                    // things without moving their finger / cursor much.
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .backgroundColor(ColorMode.current.toSitePalette().background)
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                IconButton(onClick = { close() }) {
                    CloseIcon()
                }
                Column(
                    Modifier
                        .fillMaxHeight(80.percent)
                        .padding(right = 0.75.cssRem)
                        .gap(1.5.cssRem)
                        .fontSize(1.4.cssRem),
                    horizontalAlignment = Alignment.End
                ) {
                    menuItems()
                    Row {
                        DiscordButton()
                        colorModeButton()
                    }
                    trailingItems()
                    languageDropDown()
                    Spacer()
                }
            }
        }
    }
}