package se.fluen.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun Footer(content: @Composable () -> Unit) {
    val colorMode by ColorMode.currentState
    Row(Modifier.fillMaxWidth(80.percent).gridRow(2).padding(topBottom = 1.5.cssRem).fontSize(FontSize.Medium)) {
        SimpleGrid(numColumns = numColumns(1, md = 5), Modifier.gap(2.cssRem)) {
            Column {
                Image(
                    if (colorMode.isLight) "https://downloads.fluen.se/assets/images/text_dark.svg"
                    else "https://downloads.fluen.se/assets/images/text_light.svg",
                    Modifier.margin(bottom = 8.px)
                )
                SpanText("2024 © Fluense")
                SpanText("All rights reserved")
            }
            Div { }
            content()
        }
    }
}