package se.fluen.styles

import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.border
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw

sealed interface SurfaceKind : ComponentKind

val SurfaceStyle = CssStyle<SurfaceKind> {
    val palette = colorMode.toPalette()
    base {
        Modifier
            .borderRadius(8.px)
            .boxShadow(
                5.px, 5.px, 20.px,
                color = colorMode.toSitePalette().shadow,
            )
            .color(palette.color)
    }
}

val ClickableSurfaceVariant = SurfaceStyle.addVariant {
    val colorMode = colorMode
    hover {
        Modifier.backgroundColor(color = colorMode.toPalette().background.shifted(colorMode, .1f))
            .cursor(Cursor.Pointer)
    }
}

val PopupSurfaceVariant = SurfaceStyle.addVariant {
    val palette = colorMode.toPalette()
    base {
        Modifier.backgroundColor(palette.background)
            .padding(1.cssRem)
            .border(1.px, LineStyle.Solid, palette.border)
    }
}

val DefaultSurfaceSizeVariant = SurfaceStyle.addVariant {
    base {
        Modifier.padding(2.cssRem).width(clamp(4.cssRem, 80.vw, 50.cssRem))
    }
}

val ActiveSurface = SurfaceStyle.addVariant {
    val palette = colorMode.opposite.toPalette()
    base {
        Modifier
            .backgroundColor(palette.background)
            .color(palette.color)
    }
    hover {
        Modifier
            .backgroundColor(palette.background.shifted(colorMode, 1f))
            .color(colorMode.opposite.toPalette().color)
    }
}