import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "Fluense"))
    BasePath.set("/en/")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { se.fluen.web.pages.HomePage() }
        ctx.router.register("/contact") { se.fluen.web.pages.Contact() }
        ctx.router.register("/discord") { se.fluen.web.pages.Discord() }
        ctx.router.register("/downloads") { se.fluen.web.pages.Downloads() }
        ctx.router.register("/pricing") { se.fluen.web.pages.Pricing() }
        ctx.router.register("/privacy") { se.fluen.web.pages.PrivacyPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("markdown", se.fluen.web.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("feature-image", se.fluen.web.components.widgets.FeatureImageStyle)
        ctx.theme.registerStyle("downloads-card", se.fluen.web.pages.DownloadsCardStyle)
        ctx.theme.registerStyle("tag", se.fluen.web.pages.TagStyle)
        ctx.theme.registerStyle("pricing-card", se.fluen.web.pages.PricingCardStyle)
        ctx.theme.registerStyle("pricing-feature-list", se.fluen.web.pages.PricingFeatureListStyle)
        ctx.theme.registerStyle("pricing-plan-tag", se.fluen.web.pages.PricingPlanTagStyle)
        ctx.theme.registerStyle("pricing-closing", se.fluen.web.pages.PricingClosingStyle)
        ctx.theme.registerStyle("headline-text", se.fluen.web.theme.styles.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", se.fluen.web.theme.styles.SubheadlineTextStyle)
        ctx.theme.registerStyle("page-content", se.fluen.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("nav-header", se.fluen.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("mobile-menu", se.fluen.components.sections.MobileMenuStyle)
        ctx.theme.registerStyle("desktop-menu", se.fluen.components.sections.DesktopMenuStyle)
        ctx.theme.registerStyle("panel", se.fluen.components.widgets.PanelStyle)
        ctx.theme.registerStyle("accordion", se.fluen.components.widgets.AccordionStyle)
        ctx.theme.registerStyle("lds-ring", se.fluen.components.widgets.LdsRingStyle)
        ctx.theme.registerStyle("fade-in", se.fluen.keyframes.FadeInStyle)
        ctx.theme.registerStyle("fade-out", se.fluen.keyframes.FadeOutStyle)
        ctx.theme.registerStyle("ascii-doc", se.fluen.styles.AsciiDocStyle)
        ctx.theme.registerStyle("drop-down", se.fluen.styles.DropDownStyle)
        ctx.theme.registerStyle("filter-color", se.fluen.styles.FilterColorStyle)
        ctx.theme.registerStyle("no-scrollbar", se.fluen.styles.NoScrollbar)
        ctx.theme.registerStyle("fake-item", se.fluen.styles.FakeItemStyle)
        ctx.theme.registerStyle("shimmer", se.fluen.styles.ShimmerStyle)
        ctx.theme.registerStyle("surface", se.fluen.styles.SurfaceStyle)
        ctx.theme.registerStyle("text-area", se.fluen.styles.TextAreaStyle)
        ctx.theme.registerStyle("chip", gg.bluepeak.kobwebsdk.components.widgets.ChipStyle)
        ctx.theme.registerStyle("radio-button",
                gg.bluepeak.kobwebsdk.components.widgets.radioButtonStyle)
        ctx.theme.registerVariant("-showcase-image-style",
                se.fluen.web.components.sections.ShowcaseImageStyle
        )
        ctx.theme.registerVariant("-accordion-button-style",
                se.fluen.components.widgets.AccordionButtonStyle
        )
        ctx.theme.registerVariant("-primary", se.fluen.styles.PrimaryButtonVariant
        )
        ctx.theme.registerVariant("-outlined", se.fluen.styles.OutlinedButtonVariant
        )
        ctx.theme.registerVariant("-secondary", se.fluen.styles.SecondaryButtonVariant
        )
        ctx.theme.registerVariant("-error", se.fluen.styles.ErrorButtonVariant
        )
        ctx.theme.registerVariant("-active", se.fluen.styles.ActiveButtonVariant
        )
        ctx.theme.registerVariant("-text", se.fluen.styles.TextButtonVariant
        )
        ctx.theme.registerVariant("-unstyled", se.fluen.styles.UnstyledButtonVariant
        )
        ctx.theme.registerVariant("-chip", se.fluen.styles.ChipVariant
        )
        ctx.theme.registerVariant("-active", se.fluen.styles.ActiveChip
        )
        ctx.theme.registerVariant("-nav", se.fluen.styles.NavLinkVariant
        )
        ctx.theme.registerVariant("-button", se.fluen.styles.ButtonLinkVariant
        )
        ctx.theme.registerVariant("-secondary-button", se.fluen.styles.SecondaryButtonLinkVariant
        )
        ctx.theme.registerVariant("-outlined-button", se.fluen.styles.OutlinedButtonLinkVariant
        )
        ctx.theme.registerVariant("-card", se.fluen.styles.CardLinkVariant
        )
        ctx.theme.registerVariant("-unstyled", se.fluen.styles.UnstyledLinkVariant
        )
        ctx.theme.registerVariant("-clickable", se.fluen.styles.ClickableSurfaceVariant
        )
        ctx.theme.registerVariant("-popup", se.fluen.styles.PopupSurfaceVariant
        )
        ctx.theme.registerVariant("-default-surface-size", se.fluen.styles.DefaultSurfaceSizeVariant
        )
        ctx.theme.registerVariant("-active", se.fluen.styles.ActiveSurface
        )
        ctx.theme.registerVariant("-outlined", se.fluen.styles.OutlinedTextAreaVariant
        )
        ctx.theme.registerVariant("-small-text", se.fluen.styles.SmallText
        )
        ctx.theme.registerVariant("-circle", gg.bluepeak.kobwebsdk.styles.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
        )
        ctx.theme.registerVariant("-icon", gg.bluepeak.kobwebsdk.styles.IconLinkVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                se.fluen.components.sections.SideMenuSlideInAnim)
        ctx.theme.registerKeyframes("panel", se.fluen.components.widgets.PanelKeyframes)
        ctx.theme.registerKeyframes("lds-ring", se.fluen.components.widgets.LdsRing)
        ctx.theme.registerKeyframes("loading", se.fluen.keyframes.LoadingKeyframes)
        ctx.theme.registerKeyframes("slide-in-left", se.fluen.keyframes.SlideInLeftKeyframes)
        ctx.theme.registerKeyframes("slide-in-top", se.fluen.keyframes.SlideInTopKeyframes)
        ctx.theme.registerKeyframes("fade-out", se.fluen.keyframes.FadeOutKeyframes)
        ctx.theme.registerKeyframes("fade-in", se.fluen.keyframes.FadeInKeyframes)
        ctx.theme.registerKeyframes("shimmer", se.fluen.styles.ShimmerAnimation)
        se.fluen.web.theme.styles.initSiteStyles(ctx)
        se.fluen.styles.replaceSilkStyles(ctx)
        se.fluen.theme.initTheme(ctx)
        se.fluen.theme.initFonts(ctx)
        se.fluen.theme.initStyleSheet(ctx)
        gg.bluepeak.kobwebsdk.colorMode.initColorMode(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        se.fluen.web.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
