package se.fluen.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.mdi.IconStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLanguage
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.text.SpanText
import gg.bluepeak.kobwebsdk.styles.CircleButtonVariant
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import gg.bluepeak.kobwebsdk.values.Locale
import org.jetbrains.compose.web.css.px
import se.fluen.styles.SmallText

@Composable
fun LanguageSelector(
    headline: String,
    currentLocale: String,
    availableLocales: List<Locale>,
    placement: PopupPlacement = PopupPlacement.BottomRight,
    onLocaleChange: (Locale) -> Unit,
) {
    Popup({
        MdiLanguage(style = IconStyle.ROUNDED)
    }, CircleButtonVariant, modifier = Modifier.height(40.px).width(40.px), placement = placement) {
        SpanText(
            headline,
            variant = SmallText
        )
        SimpleGrid(numColumns(2), Modifier.fillMaxWidth()) {
            availableLocales.forEach { locale ->
                Button({
                    onLocaleChange(locale)
                }, variant = UncoloredButtonVariant) {
                    SpanText(locale.full)
                }
            }
        }
    }
}