package se.fluen.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandLess
import com.varabyte.kobweb.silk.components.icons.mdi.MdiExpandMore
import com.varabyte.kobweb.silk.components.layout.DividerVars
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import org.jetbrains.compose.web.css.*

val PanelKeyframes = Keyframes {
    from { Modifier.maxHeight(0.px) }
    to { Modifier.maxHeight(100.percent) }
}

val PanelStyle = CssStyle {
    base {
        Modifier.overflow(Overflow.Hidden).animation(PanelKeyframes.toAnimation(colorMode, .5.s))
            .padding(left = 1.cssRem)
            .margin(bottom = 1.cssRem)
    }
    cssRule(".span") {
        Modifier.whiteSpace(WhiteSpace.Normal)
    }
}

val AccordionStyle = CssStyle {
    base { Modifier.fillMaxWidth() }
    Breakpoint.MD {
        Modifier.fillMaxWidth(70.percent)
    }
}

val AccordionButtonStyle = ButtonStyle.addVariant(@Composable { ButtonStyle.toModifier(UncoloredButtonVariant) }) {
    base {
        Modifier.fillMaxWidth().borderRadius(0.px).borderBottom(1.px, LineStyle.Solid, DividerVars.Color.value())
    }
}

data class AccordionItem(val title: String, val content: @Composable ColumnScope.() -> Unit)

@Composable
fun Accordion(items: List<AccordionItem>) {
    val ctx = rememberPageContext()
    Column(AccordionStyle.toModifier()) {
        items.forEach { item ->
            var isOpen by remember { mutableStateOf(false) }
            Button({ isOpen = !isOpen }, variant = AccordionButtonStyle) {
                Row(Modifier.width(100.vw), horizontalArrangement = Arrangement.Start) {
                    SpanText(item.title)
                    Spacer()
                    if (isOpen) MdiExpandLess() else MdiExpandMore()
                }
            }
            if (isOpen || ctx.isExporting) Column(PanelStyle.toModifier()) {
                item.content(this)
            }
        }
    }
}