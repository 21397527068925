package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val locale: String = "en"

  override val fluense: String = "Fluense"

  override val home: String = "Home"

  override val about: String = "About"

  override val blog: String = "Blog"

  override val toggle_color_mode: String = "Toggle color mode"

  override val login: String = "Login"

  override val signup: String = "Sign up"

  override val signup_now: String = "Sign up now"

  override val contact: String = "Contact"

  override val contact_sales: String = "Contact Sales"

  override val features: String = "Features"

  override val hero_headline: String = "The smartest way to learn a language"

  override val hero_subheadline: String = "Discover the power of Fluense"

  override val join_now: String = "Join now"

  override val learn_more: String = "Learn more"

  override val try_now: String = "Try now"

  override val get_started: String = "Get started"

  override val explore_features: String = "Explore features"

  override val feature: String = "Feature"

  override val pricing: String = "Pricing"

  override val about_us: String = "About us"

  override val privacy_policy: String = "Privacy Policy"

  override val terms_of_service: String = "Terms of Service"

  override val copyright: String = "2024 © Fluense"

  override val all_rights_reserved: String = "All rights reserved"

  override val coming_soon: String = "Coming soon"

  override val language_selector_headline: String = "Select your language"

  override val colormode_light: String = "Day"

  override val colormode_dark: String = "Night"

  override val colormode_auto: String = "Auto"

  override val closing_headline: String = "Try Fluense for Free"

  override val closing_subheadline: String =
      "Explore limitless learning. Base version is free, forever."

  override val feature_overview_headline: String = "Take Your Language Skills to the Next Level"

  override val feature_tag_focused_learning: String = "Focused Learning"

  override val feature_tag_study_modes: String = "Study Modes"

  override val feature_tag_cloud: String = "Cloud Sync"

  override val feature_tag_offline: String = "Offline Learning"

  override val feature_tag_ai: String = "AI Technology"

  override val feature_study_modes_headline: String = "Tailor Your Learning Experience"

  override val feature_study_modes_description: String = """
      |Explore varied study modes like flashcard quizzes, reading
      |        comprehension and
      |        fill the gap exercises. Adapt your approach to match your language goals.
      |    
      """.trimMargin()

  override val feature_study_modes_subheadline: String = "Study, Your Way"

  override val feature_smart_headline: String = "Intelligent Learning with AI"

  override val feature_smart_description: String = """
      |Fluense employs advanced AI algorithms, including spaced repetition, to
      |        personalize your language learning journey for maximum efficiency.
      |    
      """.trimMargin()

  override val feature_smart_subheadline: String = "Smarter Learning"

  override val feature_cloud_headline: String = "Learn Anywhere, Anytime"

  override val feature_cloud_description: String = """
      |No boundaries to your language journey. Whether offline or online, Fluense
      |        ensures your learning is uninterrupted. Study at your pace, and your progress seamlessly syncs across devices.
      |    
      """.trimMargin()

  override val feature_cloud_subheadline: String = "Cloud and Offline"

  override val showcase_headline: String = "Experience the best language learning companion"

  override val showcase_subheadline: String = "Take your first step towards fluency"

  override val pricing_headline: String =
      "Our pricing plans are designed to fit your learning needs"

  override val basic: String = "Basic"

  override val pro: String = "Pro"

  override val business: String = "Business"

  override val most_popular: String = "Most popular"

  override val price_monthly: String = "/month"

  override val free: String = "Free"

  override val pricing_basic_description: String = "Free forever"

  override val pricing_pro_description: String = "For focused learners"

  override val pricing_business_description: String = "For organisations with advanced needs"

  override val get_started_basic: String = "Get started with Basic"

  override val get_started_pro: String = "Get started with Pro"

  override val get_started_business: String = "Get started with Business"

  override val frequently_asked_questions: String = "Frequently Asked Questions"

  override val pricing_beta_description: String = "Free during beta"

  override val pricing_beta_cta: String = "Join on Discord to apply"

  override val pricing_feature_cloud: String = "Cloud Sync"

  override val pricing_feature_offline: String = "Offline Learning"

  override val pricing_feature_ai: String = "AI Technology"

  override val pricing_feature_grammar: String = "Grammar Learning"

  override val pricing_feature_pronunciation: String = "Pronunciation"

  override val pricing_feature_pre_release: String = "Pre-release Features"

  override val pricing_feature_collaborative_decks: String = "Collaborative Decks"

  override val pricing_feature_custom_decks: String = "Custom Decks"

  override val pricing_feature_exam: String = "Exam mode"

  override val pricing_feature_progress_tracking: String = "Progress Tracking"

  override val pricing_feature_ad_free: String = "Ad-free"

  override val pricing_feature_vocabulary: String = "Vocabulary Learning"

  override val pricing_feature_core_modes: String = "Core Study Modes"

  override val pricing_feature_advanced_modes: String = "Advanced Study Modes"

  override val pricing_feature_advanced_analytics: String = "Advanced Analytics"

  override val pricing_feature_priority_support: String = "Priority Support"

  override val pricing_question_free_trial: String = "Is there a free trial available?"

  override val pricing_answer_free_trial: String = """
      |Yes, we offer a free trial of our Pro Plan, so you can experience the full
      |        range of Fluense Pro features.
      |    
      """.trimMargin()

  override val pricing_question_annual: String = "Is there a discount for annual subscriptions?"

  override val pricing_answer_annual: String = """
      |Yes, we offer discounted rates for annual subscriptions on our Pro and Business
      |        Plans.
      |    
      """.trimMargin()

  override val pricing_question_bulk: String = "Do you offer bulk discounts?"

  override val pricing_answer_bulk: String = """
      |Yes absolutely, we offer bulk discounts for classrooms with 10 or more users.
      |        Please contact us for more information.
      |    
      """.trimMargin()

  override val pricing_closing_headline: String = "Get started with Fluense today"

  override val pricing_closing_subheadline: String =
      "Start optimising your language learning today."

  override val contact_headline: String = "Contact Us"

  override val contact_subheadline: String = """
      |Explore the future of language learning with us. Feel free to get in touch.
      |    
      """.trimMargin()

  override val contact_name: String = "Name"

  override val contact_email: String = "Email"

  override val contact_message: String = "Message"

  override val contact_send: String = "Send message"

  override val contact_name_placeholder: String = "Full Name"

  override val contact_email_placeholder: String = "Email Address"

  override val contact_message_placeholder: String = "How can we improve your learning experience?"

  override val contact_success_headline: String = "Thank you for your message"

  override val contact_success_subheadline: String = "We will get back to you as soon as possible."

  override val downloads: String = "Downloads"

  override val downloads_headline: String = "Download the Fluense app"

  override val downloads_windows: String = "Fluense for Windows"

  override val downloads_macos: String = "Fluense for Mac"

  override val downloads_android: String = "Fluense for Android"

  override val downloads_no_play: String = "Don't have Google Play?"

  override val downloads_android_apk: String = "Download the app here"

  override val downloads_ios: String = "Fluense for iOS"

  override val download_now: String = "Download now"

  override val newsletter_headline: String = "Be the first to know"

  override val newsletter_subheadline: String = """
      |Sign up for our newsletter and stay updated on the latest news and features.
      |    
      """.trimMargin()

  override val newsletter_subscribe: String = "Subscribe"
}
