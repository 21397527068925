package se.fluen.web.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import se.fluen.components.widgets.ColorModeButton
import se.fluen.styles.ButtonLinkVariant
import se.fluen.styles.NavLinkVariant
import se.fluen.theme.Theme
import se.fluen.web.components.widgets.LanguageSelector
import se.fluen.web.values.Res

@Composable
private fun MenuItems() {
    Link(Res.link.home, Res.string.home, variant = NavLinkVariant)
    Link(Res.link.about, Res.string.about, variant = NavLinkVariant)
    Link(Res.link.pricing, Res.string.pricing, variant = NavLinkVariant)
    Link(Res.link.downloads, Res.string.downloads, variant = NavLinkVariant)
    Link(Res.link.blog, Res.string.blog, variant = NavLinkVariant)
    Link(Res.link.contact, Res.string.contact, variant = NavLinkVariant)
}

@Composable
private fun AppItems() {
    val breakpoint = rememberBreakpoint()
    Link(Res.link.login, Res.string.login, variant = NavLinkVariant)
    Link(
        Res.link.register,
        Res.string.signup,
        variant = if (breakpoint.ordinal < Breakpoint.MD.ordinal) NavLinkVariant else ButtonLinkVariant,
        modifier = Modifier.thenIf(breakpoint.ordinal < Breakpoint.MD.ordinal, Modifier.color(Theme.primary))
    )
}

@Composable
private fun ColorModeButton() {
    ColorModeButton(
        Res.string.colormode_light,
        Res.string.colormode_dark,
        Res.string.colormode_auto
    )
}

@Composable
fun NavHeader() {
    se.fluen.components.sections.NavHeader(
        { MenuItems() },
        { AppItems() },
        { ColorModeButton() },
        { LanguageSelector() })
}
