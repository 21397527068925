package se.fluen.styles

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.InputStyle
import com.varabyte.kobweb.silk.components.forms.InputVars
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.disabled
import com.varabyte.kobweb.silk.style.selectors.focusVisible
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.not
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.size.BorderRadiusVars
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.css.*

sealed interface DropDownKind : ComponentKind

val DropDownStyle = CssStyle<DropDownKind>(@Composable { InputStyle.toModifier() }) {
    base {
        Modifier
            .backgroundImage(BackgroundImage.of(url("drawable/expand_more.svg")))
            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .backgroundPosition(BackgroundPosition.of(CSSPosition(100.percent)))
            .transition(Transition.all(0.1.s, AnimationTimingFunction.Ease))
            .cursor(Cursor.Pointer)
            .border(1.px, LineStyle.Solid, InputVars.BorderColor.value())
            .borderRadius(BorderRadiusVars.MD.value())
            .paddingInline(0.625.cssRem, 1.8.cssRem)
    }
    (hover + not(disabled)) { Modifier.border { color(InputVars.BorderHoverColor.value()) } }
    (focusVisible + not(disabled)) {
        Modifier.border(1.px, LineStyle.Solid, InputVars.BorderFocusColor.value())
            .boxShadow(spreadRadius = 1.px, color = InputVars.BorderFocusColor.value())
            .backgroundImage(BackgroundImage.of(url("drawable/expand_less.svg")))
    }
//    cssRule(":focus + .selectSpan, select:has(> option:checked:not([value=\"\"])) + .selectSpan") {
//        Modifier
//            .fontSize(1.cssRem)
//            .color(Theme.primary)
//            .translateY((-1.2).cssRem)
//    }
    cssRule("> option") {
        Modifier
            .color(colorMode.toPalette().color)
            .backgroundColor(colorMode.toPalette().background)
    }
}