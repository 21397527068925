package se.fluen.web

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.style.toModifier
import gg.bluepeak.kobwebsdk.colorMode.colorModeConfig
import io.github.skeptick.libres.strings.PluralForm
import io.github.skeptick.libres.strings.PluralRule
import io.github.skeptick.libres.strings.PluralRules
import org.jetbrains.compose.web.css.vh
import se.fluen.theme.Theme

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    PluralRules["zh"] = PluralRule {
        PluralForm.Other
    }
    SilkApp {
        colorModeConfig(Theme.white, Theme.black)

        Surface(
            SmoothColorStyle.toModifier()
                .minHeight(100.vh)
                .scrollBehavior(ScrollBehavior.Smooth)
        ) {
            content()
        }
    }
}