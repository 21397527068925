package se.fluen.modifiers

import com.varabyte.kobweb.compose.css.functions.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.filter
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.percent

fun Modifier.filterToWhite() = Modifier.filter(
    invert(92.percent),
    sepia(1.percent),
    saturate(181.percent),
    hueRotate(202.deg),
    brightness(106.percent),
    contrast(96.percent)
)

fun Modifier.filterToBlack() = Modifier.filter(
    invert(4.percent),
    sepia(1.percent),
    saturate(4742.percent),
    hueRotate(202.deg),
    brightness(92.percent),
    contrast(83.percent)
)