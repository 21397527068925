package se.fluen.styles

import com.varabyte.kobweb.compose.css.CSSColor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.px

val AsciiDocStyle = CssStyle {
    val palette = colorMode.toPalette()
    val sitePalette = colorMode.toSitePalette()
    cssRule(" .text-center") {
        Modifier.textAlign(TextAlign.Center)
    }
    cssRule(" .text-end") {
        Modifier.textAlign(TextAlign.End)
    }
    cssRule(" .text-justify") {
        Modifier.textAlign(TextAlign.Justify)
    }
    cssRule(" .text-evenly") {
        Modifier.textAlign(TextAlign.Justify).letterSpacing(2.px)
    }
    cssRule(" .content") {
        Modifier.fillMaxWidth()
    }
    cssRule(" pre") {
        Modifier.fontFamily("Poppins").whiteSpace(WhiteSpace.PreWrap)
    }
    cssRule(" a:any-link") {
        Modifier.textDecorationLine(TextDecorationLine.None).color(sitePalette.primary)
    }
    cssRule(" mark") {
        Modifier.color(CSSColor.Inherit).backgroundColor(palette.background.shifted(colorMode, .1f))
    }
    cssRule(" .red") {
        Modifier.color(sitePalette.error)
    }
    cssRule(" .green") {
        Modifier.color(sitePalette.primary)
    }
    cssRule(" .blue") {
        Modifier.color(Color.rgb(42, 75, 245))
    }
    cssRule(" .line-through") {
        Modifier.textDecorationLine(TextDecorationLine.LineThrough)
    }
    cssRule(" .strike-through") {
        Modifier.textDecorationLine(TextDecorationLine.LineThrough)
    }
    cssRule(" .underline") {
        Modifier.textDecorationLine(TextDecorationLine.Underline)
    }
    cssRule(" .overline") {
        Modifier.textDecorationLine(TextDecorationLine.Overline)
    }
}