package gg.bluepeak.kobwebsdk.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val CircleButtonVariant = ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val UncoloredButtonVariant = ButtonStyle.addVariantBase {
    val palette = colorMode.toPalette()
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
        .setVariable(ButtonVars.BackgroundHoverColor, palette.background.shifted(this.colorMode))
        .setVariable(ButtonVars.BackgroundFocusColor, palette.background.shifted(this.colorMode))
        .setVariable(
            ButtonVars.BackgroundPressedColor,
            palette.background.shifted(this.colorMode).shifted(this.colorMode)
        )
        .setVariable(ButtonVars.Color, this.colorMode.toPalette().color)
}