package se.fluen.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun Anchor(id: String) {
    Div(
        Modifier.id(id).display(DisplayStyle.Block).position(Position.Relative).top((-75).px)
            .visibility(Visibility.Hidden).toAttrs()
    )
}