package se.fluen.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.addVariant
import org.jetbrains.compose.web.css.cssRem

val SmallText = SpanTextStyle.addVariant {
    base {
        Modifier.fontSize(0.8.cssRem)
    }
}