package gg.bluepeak.kobwebsdk.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonKind
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.style.CssStyleVariant
import gg.bluepeak.kobwebsdk.styles.CircleButtonVariant
import gg.bluepeak.kobwebsdk.styles.UncoloredButtonVariant
import org.jetbrains.compose.web.css.em

@Composable
fun IconButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<ButtonKind>? = null,
    content: @Composable () -> Unit,
) {
    Button(
        onClick = { onClick() },
        Modifier.setVariable(ButtonVars.FontSize, 1.em)
            .then(modifier), // Make button icon size relative to parent container font size
        variant = variant?.let { CircleButtonVariant.then(UncoloredButtonVariant).then(it) }
            ?: CircleButtonVariant.then(UncoloredButtonVariant),
    ) {
        content()
    }
}