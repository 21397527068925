package se.fluen.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.CssStyle
import se.fluen.modifiers.filterToBlack
import se.fluen.modifiers.filterToWhite

val FilterColorStyle = CssStyle {
    base {
        if (colorMode.isDark) Modifier.filterToWhite() else Modifier.filterToBlack()
    }
}