package se.fluen.styles

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.s
import se.fluen.keyframes.LoadingKeyframes

val FakeItemStyle = CssStyle {
    base {
        Modifier.backgroundImage(linearGradient(120.deg) {
            add(Colors.Black, 30.percent)
            add(Colors.Grey, 50.percent)
            add(Colors.Black, 100.percent)
        })
            .backgroundSize(BackgroundSize.Companion.of(400.percent))
            .animation(
                LoadingKeyframes.toAnimation(
                    colorMode = colorMode,
                    duration = .8.s,
                    timingFunction = AnimationTimingFunction.EaseInOut,
                    iterationCount = AnimationIterationCount.Infinite
                )
            )
    }
}

val ShimmerAnimation = Keyframes {
    from {
        Modifier.backgroundPosition(BackgroundPosition.of(CSSPosition(110.percent)))
    }
    to {
        Modifier.backgroundPosition(BackgroundPosition.of(CSSPosition((-20).percent)))
    }
}

val ShimmerStyle = CssStyle {
    val palette = colorMode.toSitePalette()
    base {
        Modifier
            .background(
                Background.of(
                    image = BackgroundImage.of(
                    linearGradient(angle = (-45).deg) {
                        add(palette.surface)
                        add(palette.surface)
                        add(palette.shadow)
                        add(palette.surface)
                        add(palette.surface)
                    }
                )
                )
            )
            .backgroundSize(BackgroundSize.of(400.percent))
            .animation(
                ShimmerAnimation.toAnimation(
                    duration = 1.5.s,
                    timingFunction = AnimationTimingFunction.Linear,
                    iterationCount = AnimationIterationCount.Infinite,
                )
            )
    }
}