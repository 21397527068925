package se.fluen.keyframes

import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import org.jetbrains.compose.web.css.AnimationPlayState
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent

val LoadingKeyframes = Keyframes {
    from { Modifier.backgroundPosition(BackgroundPosition.of(CSSPosition(100.percent, 50.percent))) }
    to { Modifier.backgroundPosition(BackgroundPosition.of(CSSPosition(0.percent, 50.percent))) }
}

val SlideInLeftKeyframes = Keyframes {
    from { Modifier.translateX((-30).percent).opacity(0) }
    to { Modifier.translateX(0.percent).opacity(100) }
}

val SlideInTopKeyframes = Keyframes {
    from { Modifier.translateY((-30).percent).opacity(0) }
    to { Modifier.translateY(0.percent).opacity(100) }
}

val FadeOutKeyframes = Keyframes {
    from { Modifier.opacity(100) }
    to { Modifier.opacity(0) }
}

val FadeInKeyframes = Keyframes {
    from { Modifier.opacity(0) }
    to { Modifier.opacity(100) }
}

val FadeInStyle = CssStyle {
    base {
        Modifier
            .animation(
                FadeInKeyframes.toAnimation(
                    200.ms,
                    AnimationTimingFunction.EaseIn,
                    playState = AnimationPlayState.Running
                )
            )
    }
}

val FadeOutStyle = CssStyle {
    base {
        Modifier
            .animation(
                FadeOutKeyframes.toAnimation(
                    150.ms,
                    AnimationTimingFunction.EaseIn,
                    playState = AnimationPlayState.Running
                )
            )
    }
}