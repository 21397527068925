package se.fluen.styles

import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.forms.InputVars
import com.varabyte.kobweb.silk.style.ComponentKind
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.*
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import se.fluen.theme.Theme

sealed class TextAreaKind : ComponentKind

val TextAreaStyle = CssStyle<TextAreaKind> {
    base {
        Modifier
            .styleModifier { property("appearance", "none") } // Disable browser styles
            .color(ColorVar.value())
            .height(InputVars.Height.value())
            .fontSize(InputVars.FontSize.value())
            .backgroundColor(Colors.Transparent)
            .outline(0.px, LineStyle.Solid, Colors.Transparent) // Disable, we'll use box shadow instead
            .border(0.px, LineStyle.Solid, Colors.Transparent) // Overridden by variants
            .transition(
                Transition.group(
                    listOf("border-color", "box-shadow", "background-color"),
                    duration = InputVars.ColorTransitionDuration.value()
                )
            )
            .fontFamily("Poppins")
            .fontSize(1.cssRem)
    }
}

val OutlinedTextAreaVariant = TextAreaStyle.addVariant {
    fun Modifier.bordered(color: CSSColorValue): Modifier {
        return this.border(1.px, LineStyle.Solid, color).boxShadow(spreadRadius = 1.px, color = color)
    }

    val padding = .625.cssRem
    base {
        Modifier
            .paddingInline(start = padding, end = padding)
            .borderRadius(.375.cssRem)
            .border(1.px, LineStyle.Solid, InputVars.BorderColor.value())
            .setVariable(InputVars.BorderFocusColor, Theme.primary)
    }

    ariaInvalid { Modifier.bordered(InputVars.BorderInvalidColor.value()) }
    (hover + not(disabled)) { Modifier.border { color(InputVars.BorderHoverColor.value()) } }
    (focusVisible + not(disabled)) { Modifier.bordered(InputVars.BorderFocusColor.value()) }

}