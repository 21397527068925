package se.fluen.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.http.HttpMethod
import com.varabyte.kobweb.browser.http.fetch
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.TextInput
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.TextArea
import se.fluen.styles.OutlinedTextAreaVariant
import se.fluen.styles.TextAreaStyle
import se.fluen.web.components.layouts.PageLayout
import se.fluen.web.values.Res

@Page
@Composable
fun Contact() {
    val scope = rememberCoroutineScope()
    var name by remember { mutableStateOf("") }
    var email by remember { mutableStateOf("") }
    var message by remember { mutableStateOf("") }
    var sent by remember { mutableStateOf(false) }

    suspend fun send() {
        window.fetch(
            HttpMethod.POST,
            resource = "https://herald.bluepeak.cc/api/message",
            headers = mapOf("Content-Type" to "application/json"),
            body = """{
  "site": "fluen.se",
  "type": "contact",
  "email": "$email",
  "content": "$message",
  "name": "$name"
}""".encodeToByteArray()
        )
        sent = true
    }

    PageLayout(Res.string.contact) {
        Row(
            Modifier.fillMaxWidth(80.percent).margin(topBottom = 4.cssRem),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            Image(
                Res.image.contact,
                Modifier.height(clamp(20.cssRem, 25.vw, 25.cssRem)).displayIfAtLeast(Breakpoint.MD)
            )
            Column(Modifier.width(clamp(20.cssRem, 25.vw, 28.cssRem)).gap(.4.cssRem)) {
                if (sent) {
                    H1 {
                        SpanText(Res.string.contact_success_headline)
                    }
                    SpanText(Res.string.contact_success_subheadline)
                } else {
                    H1 {
                        SpanText(Res.string.contact_headline)
                    }
                    SpanText(Res.string.contact_subheadline, Modifier.margin(bottom = 2.cssRem))
                    SpanText(Res.string.contact_name)
                    TextInput(
                        name,
                        { name = it },
                        Modifier.fillMaxWidth(),
                        placeholder = Res.string.contact_name_placeholder
                    )
                    SpanText(Res.string.contact_email)
                    TextInput(
                        email,
                        { email = it },
                        Modifier.fillMaxWidth(),
                        placeholder = Res.string.contact_email_placeholder
                    )
                    SpanText(Res.string.contact_message)
                    TextArea(message, TextAreaStyle.toModifier(OutlinedTextAreaVariant).fillMaxWidth().toAttrs {
                        placeholder(Res.string.contact_message_placeholder)
                        onInput { message = it.value }
                    })
                    Button({ scope.launch { send() } }, Modifier.fillMaxWidth()) {
                        SpanText(Res.string.contact_send)
                    }
                }
            }
        }
    }
}