package gg.bluepeak.kobwebsdk.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.rowClasses
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.after
import com.varabyte.kobweb.silk.style.toAttrs
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.RadioGroupScope
import org.jetbrains.compose.web.dom.RadioInput
import org.jetbrains.compose.web.dom.Span

val radioButtonStyle = CssStyle {
    val palette = colorMode.toSitePalette()
    base {
        Modifier
            .border {
                style(LineStyle.Solid)
                color(palette.primary)
                width(1.px)
            }
            .borderRadius(30.px)
            .size(24.px)
            .display(DisplayStyle.InlineBlock)
            .position(Position.Relative)
            .cursor(Cursor.Pointer)
    }
    after {
        Modifier
            .content("")
            .display(DisplayStyle.Block)
            .position(Position.Absolute)
            .top(50.percent)
            .left(50.percent)
            .translate((-50).percent, (-50).percent)
            .scale(0)
            .size(16.px)
            .backgroundColor(palette.primary)
            .borderRadius(30.px)
            .transition(Transition.all(duration = 0.3.s))
    }
    cssRule(".x, input[type=\"radio\"]:checked + :after") {
        Modifier
            .translate((-50).percent, (-50).percent)
            .scale(1)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun <T> RadioGroupScope<T>.RadioInput(value: T, label: String, onClick: (T) -> Unit) {
    Label(attrs = Modifier.cursor(Cursor.Pointer).onClick {
        it.preventDefault()
        onClick(value)
    }.rowClasses(verticalAlignment = Alignment.CenterVertically).gap(1.cssRem).toAttrs()) {
        RadioInput(value, attrs = Modifier.display(DisplayStyle.None).toAttrs())
        Span(radioButtonStyle.toAttrs())
        SpanText(label)
    }
}