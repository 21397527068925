package se.fluen.web.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAndroid
import com.varabyte.kobweb.silk.components.icons.mdi.MdiApple
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.colors.shifted
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import se.fluen.modifiers.filterToWhite
import se.fluen.styles.ButtonLinkVariant
import se.fluen.styles.FilterColorStyle
import se.fluen.styles.OutlinedButtonLinkVariant
import se.fluen.web.components.layouts.PageLayout
import se.fluen.web.values.Res

val DownloadsCardStyle = CssStyle {
    base {
        Modifier.fillMaxWidth().borderRadius(12.px)
            .border(1.px, LineStyle.Solid, colorMode.toPalette().background.shifted(colorMode, 0.1f))
    }
    cssRule(" .item + .item") {
        Modifier.borderTop(1.px, LineStyle.Solid, colorMode.toPalette().background.shifted(colorMode, 0.1f))
    }
}

@Page
@Composable
fun Downloads() {
    val colorMode = ColorMode.current
    PageLayout(Res.string.downloads) {
        H1 {
            SpanText(Res.string.downloads_headline)
        }
        Image(
            Res.image.downloads,
            Modifier.height(clamp(20.cssRem, 25.vw, 25.cssRem)).margin(bottom = 4.cssRem)
                .displayIfAtLeast(Breakpoint.MD)
        )

        Column(
            DownloadsCardStyle.toModifier()
        ) {
            DownloadItem(
                title = {
                    MdiAndroid()
                    SpanText(Res.string.downloads_android)
                },
                button = {
                    Link(Res.link.download_android_play, Modifier.display(DisplayStyle.Grid)) {
                        Image(Res.image.google_play, Modifier.height(2.8.cssRem))
                    }
                },
                altButton = {
                    Link(Res.link.download_android_apk, variant = OutlinedButtonLinkVariant) {
                        Column(Modifier.fontSize(.7.cssRem)) {
                            SpanText(Res.string.downloads_no_play)
                            SpanText(
                                Res.string.downloads_android_apk,
                                Modifier.textDecorationLine(TextDecorationLine.Underline)
                            )
                        }
                    }
                }
            )
            DownloadItem(
                title = {
                    MdiApple()
                    SpanText(Res.string.downloads_ios)
                },
                button = {
                    SpanText(Res.string.coming_soon)
                },
                altButton = null
            )
            DownloadItem(
                title = {
                    Image(Res.image.windows, FilterColorStyle.toModifier().height(20.px).width(24.px))
                    SpanText(Res.string.downloads_windows)
                },
                button = {
                    Link(Res.link.download_windows, variant = ButtonLinkVariant) {
                        Row(Modifier.gap(1.cssRem), verticalAlignment = Alignment.CenterVertically) {
                            Image(Res.image.windows, Modifier.filterToWhite().height(20.px).width(24.px))
                            SpanText(Res.string.download_now)
                        }
                    }
                },
                altButton = null
            )
            DownloadItem(
                title = {
                    MdiApple()
                    SpanText(Res.string.downloads_macos)
                },
                button = {
                    SpanText(Res.string.coming_soon)
                },
                altButton = null
            )

        }
    }
}

@Composable
private fun DownloadItem(
    title: @Composable () -> Unit,
    button: @Composable () -> Unit,
    altButton: @Composable (() -> Unit)? = null,
) {
    val breakpoint = rememberBreakpoint()
    if (breakpoint.ordinal < Breakpoint.MD.ordinal)
        Column(
            Modifier.classNames("item").fillMaxWidth().padding(1.cssRem).gap(1.cssRem),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Row(Modifier.gap(1.cssRem), verticalAlignment = Alignment.CenterVertically) {
                title()
            }
            button()
            altButton?.let { it() }
        }
    else
        Row(
            Modifier.classNames("item").fillMaxWidth().padding(1.cssRem).gap(2.cssRem),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Row(Modifier.gap(1.cssRem), verticalAlignment = Alignment.CenterVertically) {
                title()
            }
            Spacer()
            altButton?.let { it() }
            button()
        }
}